// import logo from './logo.svg';
import './App.css';

function Button({ text }) {
  return (
    <div className='button-29' role='button' onClick={() => { window.open('https://aghanimsecrets-athed.com', '_blank', 'noopener,noreferrer'); }}>
      <span>{text}</span>
    </div>
  )
}

function App() {
  return (
    <div className="App">
        <video
          className="header_image_video"
          autoPlay={true}
          muted={true}
          loop={true}
          playsInline={true}
          poster="https://cdn.cloudflare.steamstatic.com/apps/dota2/images/aghslab/header_3930.jpg">
            <source
              type="video/webm"
              src="https://cdn.cloudflare.steamstatic.com/apps/dota2/videos/aghanins_labyrinth/aghs_spoiler_header.webm" />
            <source
              type="video/mp4"
              src="https://cdn.cloudflare.steamstatic.com/apps/dota2/videos/aghanins_labyrinth/aghs_spoiler_header.mp4" />
        </video>
        <div className='cont'>
          {/* <h1 className='logo'>Aghanim Secrets</h1> */}
          <p className='desc'><span className='yel'>Жми</span> на волшебную кнопку, чтобы попасть на сайт!<br/>И обязательно <span className='yel'>напиши</span> в поддержку, если пришел с YouTube!<br/>Там тебе выдадут бонус! ❤️</p>
        </div>
        <Button text='Волшебная кнопка' />
    </div>
  );
}

export default App;

